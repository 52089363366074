@import '../../../../scss/theme-bootstrap';

.product-quickshop-wrapper {
  clear: both;
  position: relative;
}

.product-quickshop {
  margin: 0 auto;
  width: 100%;
  &.product-quickshop-inline {
    border-top: $color-gray solid 1px;
    border-bottom: $color-gray solid 1px;
  }
  &__content {
    padding: 0 1em;
    @include breakpoint($landscape-up) {
      padding: 0 0 1em;
    }
  }
  &__image {
    display: none;
    // show the image for portrait and larger
    @include breakpoint($medium-up) {
      display: block;
      float: $ldirection;
      margin-#{$rdirection}: 8%;
      width: 48%;
    }
    @include breakpoint($landscape-up) {
      @include swap_direction(margin, 0 2% 0 5%);
      position: relative;
      float: $ldirection;
      width: 37%;
      min-height: 350px;
    }
  }
  &__details {
    float: none;
    width: 100%;
    padding-top: 3em;
    @include breakpoint($medium-up) {
      padding-top: 2em;
      float: $rdirection;
      clear: $rdirection;
      width: 44%;
    }
    @include breakpoint($landscape-up) {
      width: 56%;
    }
  }
  &__subline {
    font-size: 14px;
    text-transform: uppercase;
    margin: 20px auto 15px;
    @include breakpoint($landscape-up) {
      margin-top: 5px;
    }
  }
  &__sku-price {
    text-transform: uppercase;
    color: $color-gray;
    text-align: $ldirection;
    font-size: 16px;
    margin-top: 10px;
    font-family: $medium-font-family;
    @include breakpoint($landscape-up) {
      font-size: 11px;
      margin-top: 0;
      font-family: $main-font;
    }
  }
  &__name {
    font-size: 19px;
    padding: 5px 0;
    h1 {
      text-transform: unset;
      font-family: $main-font;
      @include breakpoint($landscape-up) {
        font-size: 22px;
      }
    }
    a {
      text-decoration: none;
    }
  }
  &__add-to-bag-container {
    @include breakpoint($landscape-up) {
      display: flex;
      height: 60px;
    }
  }
  &__sku-select {
    @include breakpoint($landscape-up) {
      margin-#{$rdirection}: 10px;
    }
  }
  &__add-to-bag {
    width: 100%;
    margin-top: 10px;
    @include breakpoint($landscape-up) {
      width: auto;
      margin-top: 0;
    }
    .product {
      &__inventory-status {
        margin-top: 20px;
      }
    }
  }
  &__description {
    text-align: $ldirection;
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .sku-menu__container {
    @include breakpoint($landscape-up) {
      margin-bottom: 20px;
    }
    .selectBox {
      border-color: $color-dark-green;
      font-weight: bold;
      font-size: 14px;
      font-family: $main-font;
      @include breakpoint($landscape-up) {
        font-size: 12px;
        min-width: 177px;
      }
    }
  }
  &__favorites {
    @include swap_direction(padding, 1% 0 0 5%);
    text-decoration: underline;
    font-weight: bold;
    float: $rdirection;
    line-height: 45px;
    a {
      font-size: 12px;
    }
    @include breakpoint($landscape-up) {
      float: $ldirection;
    }
  }
  &__close {
    position: absolute;
    top: 0.5em;
    #{$rdirection}: 0.5em;
    border-bottom: none;
    .icon {
      font-size: 20px;
    }
    .qs-overlay-cbox & {
      display: none;
    }
  }
  &__social {
    float: $ldirection;
  }
}
